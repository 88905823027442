import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from '../../Text';
import Link from '../../Link';
import Image from '../../Image';

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Logo = ({
  logoWrapperStyle,
  logoStyle,
  titleStyle,
  withAchor,
  anchorProps,
  logoSrc,
  title,
  ...props
}) => (
  <Link {...props} {...logoWrapperStyle}>
    {withAchor ? (
      <a {...anchorProps}>
        {/* {logoSrc ? (
          <Image src={logoSrc} alt={title} {...logoStyle} />
        ) : (
          <Text content={title} {...titleStyle} />
        )} */}
        <LogoContainer>
          <Image src={logoSrc} alt={title} {...logoStyle} />
          <div style={{...titleStyle}}>{title}</div>
        </LogoContainer>
      </a>
    ) : (
      <>
        {/* {logoSrc ? (
          <Image src={logoSrc} alt={title} {...logoStyle} />
        ) : (
          <Text content={title} {...titleStyle} />
        )} */}
        <LogoContainer>
          <Image src={logoSrc} alt={title} {...logoStyle} />
          <div style={{...titleStyle}}>{title}</div>
        </LogoContainer>
      </>
    )}
  </Link>
);

Logo.propTypes = {
  logoSrc: PropTypes.string,
  title: PropTypes.string.isRequired,
  logoWrapperStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  withAchor: PropTypes.bool,
  anchorProps: PropTypes.object
};

Logo.defaultProps = {
  logoWrapperStyle: {
    display: 'inline-block',
    mr: '1rem',
    flex: 1,
    'a:hover,a:focus': {
      textDecoration: 'none'
    }
  },
  titleStyle: {
    display: 'inline-block',
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '36px',
    color: 'rgb(33, 46, 58)',
    marginTop: '-4px',
    // whiteSpace: 'nowrap'
  }
};
export default Logo;
